import React from 'react';
import s from './style.module.scss';
import {gfWorkSystem} from "../goldfish/gfWorkSystem";

export const Parallax = () => {
    const renderText = (arrayText: Array<string>) => {
        return arrayText.map((item, index) => <p key={index}>{item}</p>)
    }

    return <div className={s.containerParallax}>
        {gfWorkSystem.content.map(({id, title, text, image}, index) => <section
            key={id}
            className={s.itemImage}
            style={{backgroundImage: `url(${image})`}}
        >
            <div className={s.systemContent}>
                <div className={s.title}>
                    <span>0{index + 1}</span>
                    <h3>{title}</h3>
                </div>
                <div className={s.description}>
                    <h3>{title}</h3>
                    {renderText(text)}
                </div>
                <img className={s.image} src={image} alt={title} />
            </div>
        </section>)}
    </div>
}