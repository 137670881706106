import React, {useState} from 'react';
import s from './style.module.scss';
import {Route} from "react-router-dom";
import {HeaderRegistration} from "../../components/RegistrationComponents/HeaderRegistration";
import {Register} from "../../components/RegistrationComponents/Register";
import {ForgotPassword} from "../../components/RegistrationComponents/ForgotPassword/ForgotPassword";
import classnames from "classnames";

const Registration: React.FC = () => {
    const [alreadyEmail, setAlreadyEmail] = useState<boolean>(false)

    const handleAlreadyEmail = () => {
        setAlreadyEmail(prev => !prev)
    }

    return <div className={classnames(s.wrapper, {
        [s.forgotPassword]: alreadyEmail
    })}>
        <HeaderRegistration/>
        <div className={s.content}>
            <Route path='/register' exact render={() => <Register handleAlreadyEmail={handleAlreadyEmail} alreadyEmail={alreadyEmail} />}/>
            <ForgotPassword alreadyEmail={alreadyEmail} handleAlreadyEmail={handleAlreadyEmail} />
        </div>
    </div>
}

export default Registration;