import React, {useMemo, useState} from 'react';
import s from '../style.module.scss';
import {gfRegistration} from '../../goldfish/gfRegistration';
import {UIIcon} from '../../UI/UIIcons/UIIcon';
import {UIButton} from '../../UI/UIButton/UIButton';
import {Link} from 'react-router-dom';
import {config} from '../../../etc/config';
import {ValidationPassword} from "../ValidationPassword";

type RegisterType = {
    alreadyEmail: boolean
    handleAlreadyEmail: () => void
}

export const Register: React.FC<RegisterType> = ({alreadyEmail, handleAlreadyEmail}) => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [viewerPassword, setViewerPassword] = useState(false)

    const [checkbox, setCheckbox] = useState(false)

    const changeForm = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleCheckbox = () => {
        setCheckbox(prev => !prev)
    }

    const handlerViewerPassword = () => {
        setViewerPassword(prev => !prev)
    }

    const isDisabled = useMemo(() => {
        return [...Object.values(form)].some(item => item === '') && form.password === form.confirmPassword
    }, [form])

    const register = () => {
        fetch(`${config.HOST}api/v1/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                name: form.name,
                email: form.email,
                password: form.password,
                password_confirmation: form.confirmPassword
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.status >= 200 && res.status < 400) {
                    // console.log("Reg")
                } else {
                    if (res.data.some((item: string) => item === gfRegistration.alreadyPassword)) {
                        handleAlreadyEmail()
                    }
                }
            })
    }

    return <div className={`${s.wrapper} container`}>
        <h2>{gfRegistration.register.titlePage}</h2>
        <div className={s.content}>
            <form>
                <input className={s.input} placeholder={gfRegistration.register.name} name="name" onChange={changeForm}
                       type="text" disabled={alreadyEmail}/>
                <input className={s.input} placeholder={gfRegistration.register.email} name="email"
                       onChange={changeForm}
                       type="text" disabled={alreadyEmail}/>
                <div className={s.password}>
                    <UIIcon icon={viewerPassword ? 'viewerPass' : 'hidePass'} className={s.icon}
                            onClick={handlerViewerPassword}/>
                    <input className={s.input} placeholder={gfRegistration.register.createPassword} name="password"
                           onChange={changeForm} type={!viewerPassword ? "password" : "text"} disabled={alreadyEmail}/>
                </div>
                <ValidationPassword password={form.password} />
                <div className={s.password}>
                    <UIIcon icon={viewerPassword ? 'viewerPass' : 'hidePass'} className={s.icon}
                            onClick={handlerViewerPassword}/>
                    <input className={s.input} placeholder={gfRegistration.register.confirmPassword}
                           name="confirmPassword"
                           onChange={changeForm} type={!viewerPassword ? "password" : "text"}
                           disabled={alreadyEmail}
                    />
                </div>
                <div className={s.checkbox}>
                    <label>
                        <input onChange={handleCheckbox} type="checkbox"/>
                        <p>{gfRegistration.register.secondText}</p>
                        <span className={s.checkmark}></span>
                    </label>
                </div>
            </form>
            <div className={s.buttons}>
                <UIButton round className={s.btnRegister} onClick={register}
                          disabled={isDisabled}>{gfRegistration.register.button}</UIButton>
            </div>
            <div className={s.middleware}>
                <hr/>
                <span>{gfRegistration.or}</span>
            </div>
            <Link to='/login/google' target='_self'>
                <div className={s.withGoogle}>
                    <UIButton light className={s.btnGoogle}>Login with Google</UIButton>
                    <UIIcon className={s.iconGoogle} icon={'google'}/>
                </div>
            </Link>
        </div>
    </div>
}