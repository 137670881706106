export const gfRegistration = {
    header: {
        back: 'Back',
        login: 'Login',
        register: 'Register'
    },
    login: {
        titlePage: 'Login',
        email: 'Email address',
        password: 'Password',
        remember: 'Remember Me',
        button: 'Sign In',
        forgotPassword: 'Forgot Your Password?',

    },
    register: {
        titlePage: 'Join the community',
        name: 'Name *',
        email: 'Email address *',
        createPassword: 'Create password *',
        firstText: 'Protect your account by creating a complex password',
        confirmPassword: 'Confirm password *',
        secondText: 'By creating an account, you agree to the Harvest Terms of Service and Privacy Policy.',
        button: 'Create Account'
    },
    or: 'or',
    forgotPassword: {
        title: 'Your E-mail address is already registered in the system',
        accountAlready: 'Already an account?',
        loginHere: 'Login here',
        anotherEmail: 'Enter another email'
    },
    sendEmail: {
        title: 'Forgot your password?',
        text: 'Enter the email address  you registered with Apilon, and we’ll send you a link to reset your password.',
        placeholder: 'Email address',
        button: 'Sign In',
        notEmail: 'The email address you entered is not used in the system. Please check the entered data.'
    },
    resetPassword: {
        title: 'Reset your password',
        text: 'We sent a reset password email to ',
        redirectText: 'Please click the reset password link to set your new password.',
        description: 'Haven\'t you receive the email yet? Please check your spam folder, or '
    },
    resetPage: {
        title: 'Enter a new password'
    },
    alreadyPassword: 'The email has already been taken.'
}