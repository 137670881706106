import React from 'react';
import s from './style.module.scss';
import {gfWhatDevice} from "../../components/goldfish/gfWhatDevice";
import {MenuWhatDevice} from "../../components/MenuWhatDevice";

const WhatDevice: React.FC = () => {
    return <div className={`${s.wrapper} container`}>
        <h1>{gfWhatDevice.titlePage}</h1>
        <MenuWhatDevice />
    </div>
}

export default WhatDevice;