import React, {useCallback, useState} from 'react';
import s from './style.module.scss';
import classnames from "classnames";

type ItemMenuType = {
    title: string
    text: string
    index: number
}

export const ItemMenu: React.FC<ItemMenuType> = ({title, text, index}) => {
    const [showText, setShowText] = useState(false);
    const handleShowText = useCallback(() => {
        setShowText(prev => !prev)
    }, [])

    return <div className={classnames(s.itemWrapper, {
        [s.active]: showText
    })}>
        <span>0{index}</span>
        <div className={s.content} onClick={handleShowText}>
            <h4>{title}</h4>
            {showText && <p>{text}</p>}
        </div>
    </div>
}
