import React from 'react';
import s from './style.module.scss';
import {Header} from "../../components/Header";
import {gfMainLayout} from "../../components/goldfish/gfMainLayout";
import mainImg from './images/image-layout.png';
import {UIButton} from "../../components/UI/UIButton/UIButton";
import {Slider} from "../../components/Slider";
import {NavLink} from "react-router-dom";
import {Link} from 'react-scroll'

const MainLayout: React.FC = () => {
    return <div className={s.wrapper}>
        <header className={s.header}>
            <Header/>
        </header>
        <div className={`${s.content} container`}>
            <div className={s.left}>
                <h1 className={s.title}>{gfMainLayout.titlePage}</h1>
                <h1 className={s.secondTitle}>{gfMainLayout.secondTitle}</h1>
                <p>{gfMainLayout.textPage}</p>
                <div className={s.buttons}>
                    <div className={`${s.btn} ${s.btnText}`}>
                        <Link to="web-demo" spy={true} smooth={true} duration={100}>
                        <UIButton round>{gfMainLayout.buttonText}</UIButton>
                        </Link>
                    </div>
                    <div className={`${s.btn} ${s.btnLogin} `}>
                        <NavLink to='/login'>
                            <UIButton light>{gfMainLayout.buttonSignIn}</UIButton>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className={s.right}>
                <img src={mainImg} alt={gfMainLayout.textPage}/>
            </div>
        </div>
        <Slider />
    </div>
}

export default MainLayout;