import React, {useMemo, useState} from 'react'
import s from '../style.module.scss'
import {gfRegistration} from "../../goldfish/gfRegistration"
import {UIIcon} from "../../UI/UIIcons/UIIcon"
import {UIButton} from "../../UI/UIButton/UIButton"
import {useParams} from 'react-router-dom'
import {config} from '../../../etc/config'
import {useHistory} from 'react-router-dom'
import {ValidationPassword} from "../ValidationPassword";

export const Reset = (props: any) => {
    const [password, setPassword] = useState({
        password: '',
        copyPassword: '',
    });

    const history = useHistory()

    const email = new URLSearchParams(props.location.search).get("email")
    const {token}: any = useParams()

    const [checkbox, setCheckbox] = useState<boolean>(false)

    const [viewerPassword, setViewerPassword] = useState<boolean>(false)
    const [viewerCopyPassword, setViewerCopyPassword] = useState<boolean>(false)


    const isDisabled = useMemo(() => {
        return [...Object.values(password)].some(item => item !== '') && password.password === password.copyPassword
    }, [password])

    const resetPassword = (event: any) => {
        event.preventDefault();
        fetch(`${config.HOST}api/v1/password/reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                token: token,
                email: email,
                password: password.password
            })
        })
            .then(res => {
                if(res.status >= 200 && res.status < 400 ) {
                    alert("Пароль успешно обновлён")
                    history.push('/login')
                }
        })
    }

    const handleCheckbox = () => {
        setCheckbox(prev => !prev)
    }

    const handlerViewerPassword = () => {
        setViewerPassword(prev => !prev)
    }

    const handlerViewerCopyPassword = () => {
        setViewerCopyPassword(prev => !prev)
    }

    const changePassword = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword({...password, [e.currentTarget.name]: e.currentTarget.value});
    }

    return <div className={`${s.wrapper} container`}>
        <h2>{gfRegistration.resetPage.title}</h2>
        <div className={s.content}>
            <form onSubmit={resetPassword}>
                <div className={s.password}>
                    <UIIcon icon={viewerPassword ? 'viewerPass' : 'hidePass'} className={s.icon}
                            onClick={handlerViewerPassword}/>
                    <input className={s.input} placeholder={gfRegistration.login.password} name="password"
                           onChange={changePassword} type={!viewerPassword ? "password" : "text"} />
                </div>
                <ValidationPassword password={password.password} />

                <div className={s.password}>
                    <UIIcon icon={viewerPassword ? 'viewerPass' : 'hidePass'} className={s.icon}
                            onClick={handlerViewerCopyPassword}/>
                    <input className={s.input} placeholder={gfRegistration.login.password} name="copyPassword"
                           onChange={changePassword} type={!viewerCopyPassword ? "password" : "text"} />
                </div>
                <div className={s.checkbox}>
                    <label>
                        <input onChange={handleCheckbox} type="checkbox" />
                        <p>{gfRegistration.login.remember}</p>
                        <span className={s.checkmark}></span>
                    </label>
                </div>
            </form>
            <div className={s.buttons}>
                <UIButton round className={s.btn} onClick={resetPassword} disabled={!isDisabled}>{gfRegistration.login.button}</UIButton>
            </div>
        </div>
    </div>
}