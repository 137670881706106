export const gfFreeWebDemo = {
    titlePage: 'Book A Free Web Demo from',
    mainWord: 'apilon',
    text: 'In only 30 minutes, our product experts can guide you through Apilon as a solution and answer any questions you may have.',
    form: {
        firstName: 'First Name',
        lastName: 'Last Name',
        companyName: 'Company Name',
        companySize: 'Company Size',
        country: 'Country',
        email: 'Business Email',
        code: 'Area Code',
        phone: 'Work phone',
        firstText: 'We will only contact you to book an appoint that works for you and understand your specific requirements.',
        secondText: 'By submitting this form I confirm that I have read the privacy policy and agree to the processing of my personal data by Apilon for the stated purposes. In case of consent, I can revoke my consent to this processing at any time.',
        button: 'Book Your Demo'
    },
    dropDown: {
        companySize: [
            {
                label: '1 - 50'
            },
            {
                label: '51 - 150'
            },
            {
                label: '151 - 500'
            },
            {
                label: '501-1000'
            },
            {
                label: 'more then 1000'
            }
        ],
        area: [
            {
                label: '+375'
            },
            {
                label: '+9 887'
            },
            {
                label: '+802 17'
            }
        ]
    },
    successSendForm: {
        title: 'Thank you!',
        text: 'Your request has been successfully sent.',
        description: 'If you have any questions, please contact our support team by email - mazur@tmtsoft.cz'
    }
}