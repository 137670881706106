import React, {useEffect, useState} from 'react'
import s from './style.module.scss';
import classnames from "classnames";
import _noop from 'lodash/noop';

type DropDownProps = {
    options: Array<any>
    title: string
    placeholder: string
    isShow: boolean
    valueInput?: string
    onClick?: () => void,
    onSelect: (value: string) => void,
    selected: string
}

export const DropDown: React.FC<DropDownProps> = ({options, onSelect, title, placeholder, isShow = false,   onClick = _noop, selected}) => {
    const [valueSelect, setValueSelect] = useState<string>(title)
    const [showOptions, setShowOptions] = useState<boolean>(isShow)

    useEffect(() => {
            setValueSelect(selected === '' ? title : selected)
    }, [selected])

    useEffect(() => {
        setShowOptions(isShow)
    }, [isShow])

    const handleSelectValue = (value: string) => () => {
        setValueSelect(value)
        onSelect(value)
        setShowOptions(false)
    }

    return <div className={s.wrapper}>
        <div className={s.title} onClick={onClick}>
            <p className={s.placeholder}>{valueSelect}</p>
            <span>+</span>
        </div>
        <div className={classnames(s.content, {
            [s.active]: showOptions
        })}>
            <p>{placeholder}</p>
            {options.map(({label}, index) => <div key={index} className={s.item} onClick={handleSelectValue(label)}>
                <span>{label}</span>
            </div>)}
        </div>
    </div>
}