const HidePass = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M21.919 10.7395C21.7458 10.4874 17.5822 4.58301 11.0004 4.58301C5.35275 4.58301 0.32017 10.4534 0.108417 10.7037C-0.0354879 10.8742 -0.0354879 11.1245 0.108417 11.2959C0.32017 11.5461 5.35275 17.4166 11.0004 17.4166C16.6481 17.4166 21.6807 11.5461 21.8924 11.2959C22.0254 11.1382 22.0373 10.91 21.919 10.7395ZM11.0004 16.4999C6.47296 16.4999 2.1682 12.1823 1.07463 10.9998C2.16639 9.81638 6.46656 5.49972 11.0004 5.49972C16.2979 5.49972 20.0371 9.81088 20.9501 10.9751C19.8959 12.12 15.5664 16.4999 11.0004 16.4999Z"
                  fill="#556887"/>
            <path d="M11.0002 7.33325C8.97802 7.33325 7.3335 8.97778 7.3335 11C7.3335 13.0222 8.97802 14.6667 11.0002 14.6667C13.0224 14.6667 14.6669 13.0222 14.6669 11C14.6669 8.97778 13.0224 7.33325 11.0002 7.33325ZM11.0002 13.7501C9.48403 13.7501 8.25016 12.5162 8.25016 11C8.25016 9.48383 9.48403 8.24996 11.0002 8.24996C12.5164 8.24996 13.7503 9.48383 13.7503 11C13.7503 12.5162 12.5164 13.7501 11.0002 13.7501Z"
                  fill="#556887"/>
            <line x1="-1.19713" y1="17.8053" x2="20.8029" y2="5.80526" stroke="white" strokeWidth="5"/>
            <line x1="-0.239426" y1="17.5611" x2="21.7606" y2="5.56105" stroke="#556887"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>

)

export default HidePass;