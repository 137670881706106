import React, {useCallback, useState} from 'react';
import s from './style.module.scss';
import {gfOurOffer} from "../goldfish/gfOurOffer";
import classnames from "classnames";
import path1 from './images/path-1.png';
import path2 from './images/path-2.png';
import path3 from './images/path-3.png';

const pathAndIcons = [path1, path2, path3]

export const MenuOurOffer = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const changeIndex = useCallback((index) => () => {
        setCurrentIndex(index)
    }, [currentIndex])

    return <div className={`${s.wrapper} container`}>
        <div className={s.menu}>
            {gfOurOffer.menu.map((item, index) => <h4 className={classnames({
                [s.active]: currentIndex === index
            })} key={index} onMouseOver={changeIndex(index)}>{item}</h4>)}
        </div>
        <div className={s.mazeContent}>
            <h2 className={s.wordTask}>{gfOurOffer.task}</h2>
            <img src={pathAndIcons[currentIndex]} className={s.mazeImage}/>
            <h2 className={s.wordPurpose}>{gfOurOffer.purpose}</h2>
        </div>
    </div>
}