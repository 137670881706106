import icon_1 from '../../pages/WorkSystem/images/work_system_1.png'
import icon_2 from '../../pages/WorkSystem/images/work_system_2.png'
import icon_3 from '../../pages/WorkSystem/images/work_system_3.png'

export const gfWorkSystem = {
    titlePage: 'how the system works',
    content: [
        {
            id: 1,
            title: 'Collecting applications',
            text: [
                'Our platform gathers data from registered users or companies. Thus, the formation of applications is carried out.',
                'Apilon is a universal platform for the formation and effective management of HR processes that are faced by any company. These questions are especially relevant for small and medium-sized businesses.',
                'Within one platform, you will find the simplest automated solutions to solve important HR tasks.'
            ],
            image: icon_1,
        },
        {
            id: 2,
            title: 'Data Analysis',
            text: [
                'The collection and analysis of data are automated and in the process there is a preliminary processing of the data of candidates that are suitable for the customer\'s requirements.',
                'If the requirements are positive, a draft contract is drawn up.'
            ],
            image: icon_2,
        },
        {
            id: 3,
            title: 'Selection of candidates',
            text: [
                'Our cloud-based analysis system helps companies select a candidate that most closely matches the requirements and expectations of the business. The main criterion is an unbiased quality assessment and verification.',
                'The presented automated model of team formation solves the issues of candidate selection, for example, with a limited budget and sufficient time for the dynamic development of an employee, improving his knowledge and skills. Or there may be an opposite situation: when the budget is not a constraining factor, but the company is limited in time for the development of a specialist.',
                'In this case, the system solves the problem of selecting a ready-made top specialist in the designated area.'
            ],
            image: icon_3,
        }
    ]
}