import React from 'react';
import s from './style.module.scss';
import {gfIntegrationSystem} from "../../components/goldfish/gfIntegrationSystem";
import {MenuIntegrationSystem} from "../../components/MenuIntegrationSystem";

const IntegrationSystem: React.FC = () => {
    return <div className={`${s.wrapper} container`}>
        <h1>{gfIntegrationSystem.titlePage}</h1>
        <MenuIntegrationSystem />
    </div>
}

export default IntegrationSystem;