import icon_1 from '../Slider/images/icon-1.png'
import icon_2 from '../Slider/images/icon-2.png'
import icon_3 from '../Slider/images/icon-3.png'
import icon_4 from '../Slider/images/icon-4.png'
import icon_5 from '../Slider/images/icon-5.png'
import icon_6 from '../Slider/images/icon-6.png'
import icon_7 from '../Slider/images/icon-7.png'
import icon_8 from '../Slider/images/icon-8.png'
import icon_9 from '../Slider/images/icon-9.png'
import icon_10 from '../Slider/images/icon-10.png'
import icon_11 from '../Slider/images/icon-11.png'
import icon_12 from '../Slider/images/icon-12.png'

export const gfCarousel = [
    {
        id: 1,
        title: 'HR Software',
        icon: icon_1
    },
    {
        id: 2,
        title: 'HR Management',
        icon: icon_2
    },
    {
        id: 3,
        title: 'Recruitung',
        icon: icon_3
    },
    {
        id: 4,
        title: 'Employee Management',
        icon: icon_4
    },
    {
        id: 5,
        title: 'Applicant Tracking System',
        icon: icon_5
    },
    {
        id: 6,
        title: 'Employee Selfservice',
        icon: icon_6
    },
    {
        id: 7,
        title: 'Time-Off Management',
        icon: icon_7
    },
    {
        id: 8,
        title: 'Cloud Software',
        icon: icon_8
    },
    {
        id: 9,
        title: 'Onboarding and Offboarding',
        icon: icon_9
    },
    {
        id: 10,
        title: 'HR Reporting',
        icon: icon_10
    },
    {
        id: 11,
        title: 'Performance Management',
        icon: icon_11
    },
    {
        id: 12,
        title: 'Payroll ',
        icon: icon_12
    }

]