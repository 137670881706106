import React, {useState} from 'react'
import s from './style.module.scss'
import {gfRegistration} from '../../goldfish/gfRegistration'
import {UIButton} from '../../UI/UIButton/UIButton'
import {useHistory} from 'react-router-dom'
import {config} from "../../../etc/config";

type Step2Types = {
    email: string
    handleEmail: (value: string) => void
}

export const Step2: React.FC<Step2Types> = ({email, handleEmail}) => {
    const [isEmail, setIsEmail] = useState<boolean>(true)
    const history = useHistory()

    const changeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        handleEmail(e.currentTarget.value)
    }

    const sendReset = (event: any) => {
        event.preventDefault();
        fetch(`${config.HOST}api/v1/password/email`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({email: email})
        })
            .then((res) => res.json())
            .then(res => {
                if(res.status === 0 ) {
                    history.push('/login/forgot-password/2')
                } else {
                    setIsEmail(false)
                }
        })
    }

    return <>
        <h3>{gfRegistration.sendEmail.title}</h3>
        <p className={s.emailDescription}>{gfRegistration.sendEmail.text}</p>
        <form className={s.form} onSubmit={sendReset}>
            <input type="text" id="email" className={s.input} placeholder={gfRegistration.sendEmail.placeholder} onChange={changeEmail} />
            {!isEmail && <p>{gfRegistration.sendEmail.notEmail}</p>}
        </form>
        <UIButton round className={s.btn} disabled={email === ''} onClick={sendReset}>{gfRegistration.login.button}</UIButton>
        <h6>{gfRegistration.forgotPassword.anotherEmail}</h6>
    </>
}