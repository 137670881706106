import React from 'react';
import s from './style.module.scss';
import {gfWhatDevice} from "../goldfish/gfWhatDevice";
import {ItemMenu} from "./ItemMenu";

export const MenuWhatDevice: React.FC = () => {
    return <div className={s.wrapper}>
        {gfWhatDevice.menu.map(({id, title, text}, index) => <ItemMenu
            key={id}
            title={title}
            text={text}
            index={index + 1}
        />)}
    </div>
}