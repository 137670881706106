import React from 'react';
import './App.css';
import './components/assets/_normalize.scss'
import './components/assets/_variables.scss';
import './components/assets/_globals.scss';
import './components/assets/_markup.scss';
import MainLayout from "./pages/MainLayout";
import WorkSystem from "./pages/WorkSystem";
import Video from "./pages/Video";
import OurOffer from "./pages/OurOffer";
import IntegrationSystem from "./pages/IntegrationSystem";
import TeamApilon from "./pages/TeamApilon";
import WhatDevice from "./pages/WhatDevice";
import {Footer} from "./components/Footer";
import FreeWebDemo from "./pages/FreeWebDemo";
import Registration from "./pages/Registration";
import {Route, Switch} from "react-router-dom";
import SignIn from "./pages/SignIn";
import ResetPassword from "./pages/ResetPassword";

const App: React.FC = () => {
    return (<Switch>
            <Route path='/' exact render={() => <div className="App">
                <MainLayout/>
                <WorkSystem/>
                <Video/>
                <OurOffer/>
                <IntegrationSystem/>
                <TeamApilon/>
                <WhatDevice/>
                <FreeWebDemo/>
                <Footer/>
            </div>}/>
            <Route path='/register' exact render={() => <Registration/>}/>
            <Route path='/login' render={() => <SignIn/>}/>
            <Route path='/password/reset/:token' render={() => <ResetPassword/>}/>
        </Switch>
    );
}

export default App;
