import React from 'react';
import s from './style.module.scss';
import {gfOurOffer} from "../../components/goldfish/gfOurOffer";
import {MenuOurOffer} from "../../components/MenuOurOffer";

const OurOffer: React.FC = () => {
    return <div className={s.wrapper}>
        <h1>{gfOurOffer.titlePage}</h1>
        <MenuOurOffer />
    </div>
}

export default OurOffer;