import React, {useCallback, useState} from 'react';
import s from './style.module.scss';
import {gfIntegrationSystem} from "../goldfish/gfIntegrationSystem";
import classnames from "classnames";
import {MenuType} from "./types/menuType";

export const MenuIntegrationSystem: React.FC = () => {
    const [currentType, setCurrentType] = useState<MenuType>(MenuType.TimeTracking)

    const changeType = useCallback((type: MenuType) => () => {
        setCurrentType(type)
    }, [])

    return <div className={s.wrapper}>
        <div className={s.menu}>
            {gfIntegrationSystem.menu.map(({title, type}) => <h4 onClick={changeType(type)} className={classnames({
                [s.active]: currentType === type
            })} key={type}>{title}</h4>)}
        </div>
        <div className={s.systems}>
            {gfIntegrationSystem.systems[currentType].map(({id, icon, title, text}) => <div key={id} className={s.system}>
                <img src={icon} alt={title} />
                <h2>{title}</h2>
                <p>{text}</p>
            </div>)}
        </div>
    </div>
}