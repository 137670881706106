import React, {useEffect, useMemo, useState} from 'react'
import s from './style.module.scss'
import classnames from "classnames";
import {config} from "../../../etc/config";

type ValidationPasswordType = {
    password: string
}

export const ValidationPassword: React.FC<ValidationPasswordType> = ({password}) => {
    const [errors, setErrors] = useState<Array<string> | null>(null)

    useEffect(() => {
        if (password.length >= 8) {
            fetch(`${config.HOST}api/v1/password/validation`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify({
                    password: password
                })
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status >= 200 && res.status < 400) {
                        console.log(res.data)
                    } else {
                        setErrors(res.data)
                    }
                })
        } else if(password.length === 0) {
            setErrors(null)
        }
    }, [password])

    const percentageLeaner = useMemo(() => {
        if (errors === null) {
            return 0
        } else {
            return 100 - (errors.length / 4 * 100)
        }
    }, [errors])

    return <div className={s.wrapper}>
        <div className={s.leanerContainer}>
            <div style={{width: percentageLeaner + '%'}} className={classnames(s.leaner, {
                [s.emptyPassword]: percentageLeaner === 0,
                [s.unreliable]: percentageLeaner < 74 && percentageLeaner > 0,
                [s.secure]: percentageLeaner >= 75
            })}/>
        </div>
        {errors !== null && <div className={s.errors}>
            {errors.map((item, index) => <span className={s.error} key={index}>{item} </span>)}
        </div>}
    </div>
}