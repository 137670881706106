import React from 'react';
import s from './style.module.scss';
import {Route} from "react-router-dom";
import {HeaderRegistration} from "../../components/RegistrationComponents/HeaderRegistration";
import {Reset} from "../../components/RegistrationComponents/Reset";

const ResetPassword: React.FC = () => {
    return <div className={s.wrapper}>
        <HeaderRegistration/>
        <div className={s.content}>
            <Route path='/password/reset/:token' exact component={Reset}/>
        </div>
    </div>
}

export default ResetPassword;