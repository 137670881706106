import React from 'react';
import s from './style.module.scss';
import {MainPartWebDemo} from "../../components/WebDemoComponents/MainPartWebDemo";
import {FormWebDemo} from "../../components/WebDemoComponents/FormWebDemo";

const FreeWebDemo: React.FC = () => {
    return <div className={s.wrapper} id={"web-demo"}>
        <div className={`${s.content} container`}>
            <MainPartWebDemo />
            <FormWebDemo />
        </div>
    </div>
}

export default FreeWebDemo;
