import React from 'react'
import s from './style.module.scss'
import {gfRegistration} from "../../goldfish/gfRegistration";

type Step3Types = {
    email: string
}

export const Step3: React.FC<Step3Types> = ({email}) => {
    return <>
        <h3>{gfRegistration.sendEmail.title}</h3>
        <div className={s.textReset}>
            <p>{gfRegistration.resetPassword.text} <span>{email}</span>.</p>
            <p>{gfRegistration.resetPassword.redirectText}</p>
            <p className={s.reset}>{gfRegistration.resetPassword.description} <span>try again</span>.</p>
        </div>
    </>
}