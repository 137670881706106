import React from 'react';
import s from './style.module.scss';
import {gfRegistration} from "../../goldfish/gfRegistration";
import {NavLink} from "react-router-dom";

export const HeaderRegistration: React.FC = () => {
    return <div className={`${s.wrapper} container`}>
        <NavLink to='/' exact>&lt; {gfRegistration.header.back}</NavLink>
        <div className={s.links}>
            <NavLink to='/login' activeClassName={s.activeLink}>{gfRegistration.header.login}</NavLink>
            <NavLink to='/register' activeClassName={s.activeLink}>{gfRegistration.header.register}</NavLink>
        </div>
    </div>
}