import React, {SyntheticEvent} from 'react';
import classnames, {Mapping} from 'classnames';
import _noop from 'lodash/noop';
import * as icons from './iconData';

export interface UIIconProps {
    icon: keyof typeof icons;
    onClick?: (e: SyntheticEvent) => void;
    className?: Mapping | string;
}

export const UIIcon: React.FC<UIIconProps> = ({
                                                  icon,
                                                  className,
                                                  onClick = _noop,

                                                  ...restProps
                                              }) => {
    const Icon = icons[icon];
    return (
        <div
            className={classnames('UI__icon flex align-center', className)}
            role="presentation"
            {...restProps}
            onClick={onClick}
        >
            {!!Icon && <Icon/>}
        </div>
    );
};
