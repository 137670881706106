import React from "react"
import {gfRegistration} from "../../goldfish/gfRegistration"
import s from "./style.module.scss"
import {useHistory, Link} from 'react-router-dom'

export const Step1 = () => {
    const history = useHistory()

    const handleNextStep = () => {
        history.push('/login/forgot-password/2')
    }

    return <>
        <h3>{gfRegistration.forgotPassword.title}</h3>
        <div className={s.text}>
            <p>{gfRegistration.forgotPassword.accountAlready}</p>
            <Link to={'/login'}><span className={"ml-2"}>{gfRegistration.forgotPassword.loginHere}</span></Link>
        </div>
        <h6 onClick={handleNextStep}>{gfRegistration.forgotPassword.anotherEmail}</h6>
    </>
}