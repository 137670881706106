import React, {useMemo, useState} from 'react';
import s from '../style.module.scss';
import {gfRegistration} from "../../goldfish/gfRegistration";
import {UIIcon} from "../../UI/UIIcons/UIIcon";
import {UIButton} from "../../UI/UIButton/UIButton";
import {config} from '../../../etc/config';
import classnames from "classnames";

type LoginProps = {
    forgotPassword: boolean
    handleForgotPassword: () => void
}

export const Login: React.FC<LoginProps> = ({forgotPassword, handleForgotPassword}) => {
    const path = `${config.HOST}login/google`

    const [form, setForm] = useState({
        email: '',
        password: '',
    });

    const isDisabled = useMemo(() => {
        return [...Object.values(form)].some(item => item === '')
    }, [form])

    const login = () => {
        fetch(`${window.location.href}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({...form})
        })
            .then(res => {
                if(res.status >= 200 && res.status < 400 ) {
                    // document.location.href = `${config.HOST}app`
                    document.location.href = `${document.location.origin}/app`
                }
            })
    }

    const [viewerPassword, setViewerPassword] = useState(false)

    const [checkbox, setCheckbox] = useState(false)

    const changeForm = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleCheckbox = () => {
        setCheckbox(prev => !prev)
    }

    const handlerViewerPassword = () => {
        setViewerPassword(prev => !prev)
    }

    return <div className={`${s.wrapper} container`}>
        <h2>{gfRegistration.login.titlePage}</h2>
        <div className={classnames(s.content, {
            [s.activeForgotPassword]: forgotPassword
        })}>
            <form>
                <input className={s.input} placeholder={gfRegistration.login.email} name="email" onChange={changeForm}
                       type="text" disabled={forgotPassword}/>
                <div className={s.password}>
                    <UIIcon icon={viewerPassword ? 'viewerPass' : 'hidePass'} className={s.icon}
                            onClick={handlerViewerPassword}/>
                    <input className={s.input} placeholder={gfRegistration.login.password} name="password"
                           onChange={changeForm} type={!viewerPassword ? "password" : "text"} disabled={forgotPassword} />
                </div>
                <div className={s.checkbox}>
                    <label>
                        <input onChange={handleCheckbox} type="checkbox" disabled={forgotPassword} />
                        <p>{gfRegistration.login.remember}</p>
                        <span className={s.checkmark} />
                    </label>
                </div>
            </form>
            <div className={s.buttons}>
                <UIButton round className={s.btn} onClick={login} disabled={isDisabled || forgotPassword}>{gfRegistration.login.button}</UIButton>
                <h4 onClick={handleForgotPassword}>{gfRegistration.login.forgotPassword}</h4>
            </div>
            <div className={s.middleware}>
                <hr/>
                <span>{gfRegistration.or}</span>
            </div>
            <div className={s.withGoogle}>
                <a href={path}>
                    <UIButton light className={s.btnGoogle} disabled={forgotPassword}   >Login with Google</UIButton>
                    <UIIcon className={s.iconGoogle} icon={'google'}/>
                </a>
            </div>
        </div>
    </div>
}
