import React from 'react';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import s from './styles.module.scss';

type Props = {
    className?: string | Record<string, boolean>;
    disabled?: boolean;
    round?: boolean;
    success?: boolean;
    shrink?: boolean;
    light?: boolean;
    onClick?: (event?: any) => void;
};

export const UIButton: React.FC<Props> = ({
                                              children,
                                              className,
                                              disabled,
                                              round,
                                              success,
                                              shrink,
                                              light,
                                              onClick = _noop
                                          }) => {
    return (
        <button
            type="button"
            className={classnames('UI__button', className, {
                [s.button]: true,
                [s.round]: round,
                [s.light]: light,
                [s.success]: success,
                [s.shrink]: shrink,
            })}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};
