import React from 'react';
import ReactPlayer from "react-player";
import s from './style.module.scss';

export const Player: React.FC = () => {
    return <div className={s.player}>
        <ReactPlayer
            url="https://www.youtube.com/watch?v=_ONgZbB31aY"
            className="react-player"
            playing={false}
            width="100%"
            height="100vh"
            controls={false}
        />
    </div>
}