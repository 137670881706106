import React from 'react';
import s from './style.module.scss';
import logoIcon from './images/logo-icon.png';
import {MenuMobile} from "../MenuMoblie";

export const Header: React.FC = () => {
    return <div className={`${s.wrapper} container`}>
        {/*<div className={s.menuMobile}>*/}
        {/*    <MenuMobile/>*/}
        {/*</div>*/}
        <div className={s.left}>
            <img src={logoIcon} alt={'logo'} />
        </div>
        {/*<div className={s.menu}>*/}
        {/*    <a href="">Menu 1</a>*/}
        {/*    <a href="">Menu 2</a>*/}
        {/*    <a href="">Menu 3</a>*/}
        {/*    <a href="">Menu 4</a>*/}
        {/*</div>*/}
    </div>
}