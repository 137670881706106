import React, {useMemo, useState} from 'react'
import s from './style.module.scss'
import {gfFreeWebDemo} from "../../goldfish/gfFreeWebDemo"
import {UIButton} from "../../UI/UIButton/UIButton"
import countryList from 'react-select-country-list'
import {DropDown} from "../../DropDown"
import {SuccessSendForm} from "../SuccessSendForm"
import classnames from "classnames"
import {config} from '../../../etc/config'

const DATA_FORM = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    code: '',
    phone: ''
}

export const FormWebDemo: React.FC = () => {
    const [showCountry, setShowCountry] = useState<boolean>(false)
    const [showSizeCompany, setShowSizeCompany] = useState<boolean>(false)
    const [form, setForm] = useState({...DATA_FORM})
    const [country, setCountry] = useState<string>('')
    const [companySize, setCompanySize] = useState<string>('')
    const [successForm, setSuccessForm] = useState<boolean>(false)

    const countries = useMemo(() => countryList().getData(), [])

    const handleShowCountry = () => {
        setShowCountry(prev => !prev)
        if (showSizeCompany) {
            setShowSizeCompany(prev => !prev)
        }
    }

    const handleShowSizeCompany = () => {
        setShowSizeCompany(prev => !prev)
        if (showCountry) {
            setShowCountry(prev => !prev)
        }
    }

    const handleSelectCountry = (value: string) => {
        setCountry(value)
    }

    const handleSelectCompanySize = (value: string) => {
        setCompanySize(value)
    }

    const changeForm = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const isDisabled = useMemo(() => {
        return [...Object.values(form), companySize, country].some(item => item === '')
    }, [form, companySize, countries])


    const sendForm = () => {
        fetch(`${config.HOST}api/v1/demo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                first_name: form.firstName,
                last_name: form.lastName,
                company_name: form.companyName,
                company_size: companySize,
                country: country,
                email: form.email,
                code: form.code,
                phone: form.phone
            })
        }).then(res => {
            console.log(res)
            setSuccessForm(true)
            setCountry('')
            setCompanySize('')
            setForm({...DATA_FORM})
        })
    }

    return <div className={s.wrapper}>
        {!successForm && <>
            <form>
                <div className={s.fullName}>
                    <input onChange={changeForm} name="firstName" placeholder={gfFreeWebDemo.form.firstName}
                           value={form.firstName} type="text"/>
                    <input onChange={changeForm} name="lastName" placeholder={gfFreeWebDemo.form.lastName}
                           value={form.lastName} type="text"/>
                </div>
                <input onChange={changeForm} name="companyName" placeholder={gfFreeWebDemo.form.companyName}
                       value={form.companyName} type="text"/>
                <DropDown
                    title={gfFreeWebDemo.form.companySize}
                    options={gfFreeWebDemo.dropDown.companySize}
                    isShow={showSizeCompany}
                    placeholder="Select the number of employees"
                    onClick={handleShowSizeCompany}
                    onSelect={handleSelectCompanySize}
                    selected={companySize}
                />
                <DropDown
                    title={gfFreeWebDemo.form.country}
                    options={countries}
                    placeholder="Select your country"
                    isShow={showCountry}
                    onClick={handleShowCountry}
                    onSelect={handleSelectCountry}
                    selected={country}
                />
                <input onChange={changeForm} name="email" placeholder={gfFreeWebDemo.form.email} value={form.email}
                       type="email"/>
                <div className={s.contact}>
                    <input onChange={changeForm} name="code" placeholder={gfFreeWebDemo.form.code} value={form.code}
                           type="text"/>
                    <input onChange={changeForm} name="phone" placeholder={gfFreeWebDemo.form.phone} value={form.phone}
                           type="phone"/>
                </div>
            </form>
            <p>{gfFreeWebDemo.form.firstText}</p>
            <p>{gfFreeWebDemo.form.secondText}</p>
            <UIButton round className={s.btn} disabled={isDisabled}
                      onClick={sendForm}>{gfFreeWebDemo.form.button}</UIButton>
        </>}
        <div className={classnames(s.successPage, {
            [s.activeSuccess]: successForm
        })}>
            <SuccessSendForm/>
        </div>
    </div>
}