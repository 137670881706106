import React from 'react';
import s from './style.module.scss';
import {gfFooter} from "../goldfish/gfFooter";

export const Footer: React.FC = () => {
    return <div className={s.footer}>
        <div className={`${s.content} container`}>
            <div className={s.left}>
                <span>{gfFooter.certificateSite}</span>
            </div>
            <div className={s.right}>
                {gfFooter.menu.map((item, index) => <span key={index}>{item}</span>)}
            </div>
        </div>
    </div>
}