import React from 'react';
import s from './style.module.scss';
import {gfTeamApilon} from "../../components/goldfish/gfTeamApilon";
import {UIButton} from "../../components/UI/UIButton/UIButton";
import {Link} from 'react-scroll'

const TeamApilon: React.FC = () => {
    return <div className={s.wrapper}>
        <h2>{gfTeamApilon.titlePage}</h2>
        <p>{gfTeamApilon.text}</p>
        <Link to="web-demo" spy={true} smooth={true} duration={100}>
            <UIButton shrink className={s.btn}>{gfTeamApilon.button}</UIButton>
        </Link>
    </div>
}

export default TeamApilon;