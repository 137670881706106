import React, {useState} from 'react'
import s from './style.module.scss'
import classnames from 'classnames'
import closeIcon from '../images/close.png'
import {Route} from "react-router-dom";
import {Step2} from "./Step2";
import {Step3} from "./Step3";
import {Step1} from "./Step1";

type ForgotPasswordProps = {
    forgotPassword?: boolean
    handleForgotPassword?: () => void
    alreadyEmail?: boolean
    handleAlreadyEmail?: () => void
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({forgotPassword, handleForgotPassword, alreadyEmail, handleAlreadyEmail}) => {
    const [email, setEmail] = useState<string>('')

    const handleEmail = (value: string) => {
        setEmail(value)
    }

    return <div className={classnames(s.wrapper, {
        [s.active]: forgotPassword || alreadyEmail
    })}>
        <Route path='/login/forgot-password/1' render={() => <Step2 email={email} handleEmail={handleEmail} />}/>
        <Route path='/login/forgot-password/2' render={() => <Step3 email={email} />} />
        {alreadyEmail && <Step1 />}
        <img className={s.close} src={closeIcon} onClick={handleForgotPassword || handleAlreadyEmail} alt="x"/>
    </div>
}