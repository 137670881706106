import React from 'react'
import s from './style.module.scss'
import {gfFreeWebDemo} from '../../goldfish/gfFreeWebDemo'

export const SuccessSendForm = () => {
    return <div className={s.wrapper}>
        <h3>{gfFreeWebDemo.successSendForm.title}</h3>
        <h5>{gfFreeWebDemo.successSendForm.text}</h5>
        <p>{gfFreeWebDemo.successSendForm.description}</p>
    </div>
}