import React from 'react';
import s from './style.module.scss';
import {gfWorkSystem} from "../../components/goldfish/gfWorkSystem";
import {Parallax} from "../../components/Parallax";

const WorkSystem: React.FC = () => {

    return <div className={`${s.wrapper} container`}>
        <h1>{gfWorkSystem.titlePage}</h1>
        <Parallax />
    </div>
}

export default WorkSystem;