import React, {useEffect, useState} from 'react';
import s from './style.module.scss';
import {gfCarousel} from "../goldfish/gfCarousel";
import Carousel from "react-elastic-carousel";

export const Slider = () => {
    const [showItem, setShowItem] = useState(9)

    const myArrow = ({type, onClick, isEdge}: any) => {
        const pointer = type === 'PREV'
            ? <div className={s.leftArrow}>&lt;</div>
            : <div className={s.rightArrow}>&gt;</div>
        return (
            <button className={s.btn} onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }

    useEffect(() => {
        if (window.innerWidth > 1700) {
            setShowItem(9)
        } else if (window.innerWidth > 1600) {
            setShowItem(8)
        } else if (window.innerWidth > 1500) {
            setShowItem(7)
        } else if (window.innerWidth > 1400) {
            setShowItem(6)
        } else if (window.innerWidth > 1320) {
            setShowItem(5)
        } else if (window.innerWidth > 1000) {
            setShowItem(4)
        } else if (window.innerWidth > 750) {
            setShowItem(3)
        } else {
            setShowItem(2)
        }
    }, [])

    return <div className={s.wrapper}>
        <div className={s.container}>
            <Carousel itemsToShow={showItem} isRTL={false} enableAutoPlay={true} pagination={false} showArrows={true} renderArrow={myArrow}>
                {gfCarousel.map(({id, title, icon}) => (
                    <div className={s.item} key={id}>
                        <div className={s.icon}>
                            <img src={icon} alt={title}/>
                        </div>
                        <span>{title}</span>
                    </div>
                ))}
            </Carousel>
        </div>
    </div>
}