import React, {useState} from 'react';
import s from './style.module.scss';
import {Route} from "react-router-dom";
import {HeaderRegistration} from "../../components/RegistrationComponents/HeaderRegistration";
import {Login} from "../../components/RegistrationComponents/Login";
import classnames from "classnames";
import {ForgotPassword} from "../../components/RegistrationComponents/ForgotPassword/ForgotPassword";
import {useHistory} from 'react-router-dom'

const SignIn: React.FC = () => {
    const [forgotPassword, setForgotPassword] = useState<boolean>(false)
    const history = useHistory();

    const handleForgotPassword = () => {
        setForgotPassword(prev => !prev)
        history.push('/login/forgot-password/1')
    }

    return <div className={classnames(s.wrapper, {
        [s.forgotPassword]: forgotPassword
    })}>
        <HeaderRegistration/>
        <div className={s.content}>
            <Route path='/login' render={() => <Login forgotPassword={forgotPassword} handleForgotPassword={handleForgotPassword} />}/>
            <ForgotPassword forgotPassword={forgotPassword} handleForgotPassword={handleForgotPassword} />
        </div>
    </div>
}

export default SignIn;