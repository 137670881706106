export const gfWhatDevice = {
    titlePage: 'what we decide',
    menu: [
        {
            id: 1,
            title: 'You’ll no longer have to use separate apps',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 2,
            title: 'You’ll know what everyone is working on',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 3,
            title: 'All teams can work together, finally',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 4,
            title: 'You’ll get a birds-eye view of literally everything.',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 5,
            title: 'You’ll no longer have to use separate apps',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 6,
            title: 'You’ll know what everyone is working on',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 7,
            title: 'All teams can work together, finally',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        },
        {
            id: 8,
            title: 'You’ll get a birds-eye view of literally everything.',
            text: 'Add Kanban Boards, Gantt  charts, Calendars, and more alongside docs, conversations, reminders, spreadsheets and embeds to keep all of your work in one place.'
        }
    ]
}