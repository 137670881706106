const ViewerPass = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M21.9185 10.7395C21.7453 10.4874 17.5817 4.58301 10.9999 4.58301C5.35226 4.58301 0.319682 10.4534 0.107928 10.7037C-0.0359762 10.8742 -0.0359762 11.1245 0.107928 11.2959C0.319682 11.5461 5.35226 17.4166 10.9999 17.4166C16.6476 17.4166 21.6802 11.5461 21.8919 11.2959C22.0249 11.1382 22.0368 10.91 21.9185 10.7395ZM10.9999 16.4999C6.47247 16.4999 2.16771 12.1823 1.07414 10.9998C2.16591 9.81638 6.46607 5.49972 10.9999 5.49972C16.2974 5.49972 20.0366 9.81088 20.9496 10.9751C19.8954 12.12 15.5659 16.4999 10.9999 16.4999Z" fill="#8A9CBF"/>
            <path d="M10.9997 7.33325C8.97754 7.33325 7.33301 8.97778 7.33301 11C7.33301 13.0222 8.97754 14.6667 10.9997 14.6667C13.0219 14.6667 14.6664 13.0222 14.6664 11C14.6664 8.97778 13.0219 7.33325 10.9997 7.33325ZM10.9997 13.7501C9.48354 13.7501 8.24968 12.5162 8.24968 11C8.24968 9.48383 9.48354 8.24996 10.9997 8.24996C12.5159 8.24996 13.7498 9.48383 13.7498 11C13.7498 12.5162 12.5159 13.7501 10.9997 13.7501Z" fill="#8A9CBF"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>


);

export default ViewerPass;
