import React from 'react';
import s from './style.module.scss';
import {gfFreeWebDemo} from "../../goldfish/gfFreeWebDemo";

export const MainPartWebDemo: React.FC = () => {
    return <div className={s.wrapper}>
        <h2 className={s.title}>{gfFreeWebDemo.titlePage}</h2>
        <h1 className={s.word}>{gfFreeWebDemo.mainWord}</h1>
        <h4>{gfFreeWebDemo.text}</h4>
    </div>
}