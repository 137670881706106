import toggleIcon from '../MenuIntegrationSystem/images/time-tr_toggl.jpg';
import harvestIcon from '../MenuIntegrationSystem/images/time-tr_harvest.jpg';
import clockifyIcon from '../MenuIntegrationSystem/images/time-tr_clockify.jpg';
import duefocusIcon from '../MenuIntegrationSystem/images/time-tr_duefocus.jpg';
import everhourIcon from '../MenuIntegrationSystem/images/time-tr_everhour.jpg';
import chromeIcon from '../MenuIntegrationSystem/images/time-tr_chrome.jpg';
import pomodoneIcon from '../MenuIntegrationSystem/images/time-tr_PomoDoneApp.jpg';
import timelyIcon from '../MenuIntegrationSystem/images/time-tr_timely.jpg';
import timeneyeIcon from '../MenuIntegrationSystem/images/time-tr_timeneye.jpg';
import {MenuType} from "../MenuIntegrationSystem/types/menuType";

export const gfIntegrationSystem = {
    titlePage: 'integration in the system',
    menu: [
        {
            title: 'Time Tracking',
            type: MenuType.TimeTracking
        },
        {
            title: 'Cloud Storage',
            type: MenuType.CloudStorage
        },
        {
            title: 'Developers',
            type: MenuType.Developers
        },
        {
            title: 'Calendar',
            type: MenuType.Calendar
        },
        {
            title: 'More', type: MenuType.More
        }
    ],
    systems: {
        [MenuType.TimeTracking]: [
            {
                id: 1,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
            {
                id: 2,
                icon: harvestIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 3,
                icon: clockifyIcon,
                title: 'Time Tracking Integration',
                text: 'Track time on tasks right from Apilon with just one click. '
            },
            {
                id: 4,
                icon: duefocusIcon,
                title: 'Duefocus time tracking',
                text: 'Fully friendly with your project management tool'
            },
            {
                id: 5,
                icon: everhourIcon,
                title: 'Everhour Time Tracking',
                text: 'Everhour makes budgeting and invoicing simple!'
            },
            {
                id: 6,
                icon: timelyIcon,
                title: 'Название сервиса',
                text: 'Короткое описание сервиса в пару строк. '
            },
            {
                id: 7,
                icon: chromeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 8,
                icon: pomodoneIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 9,
                icon: timeneyeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 10,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
            {
                id: 11,
                icon: harvestIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 12,
                icon: clockifyIcon,
                title: 'Time Tracking Integration',
                text: 'Track time on tasks right from Apilon with just one click. '
            },
            {
                id: 13,
                icon: duefocusIcon,
                title: 'Duefocus time tracking',
                text: 'Fully friendly with your project management tool'
            },
            {
                id: 14,
                icon: everhourIcon,
                title: 'Everhour Time Tracking',
                text: 'Everhour makes budgeting and invoicing simple!'
            },
            {
                id: 15,
                icon: timelyIcon,
                title: 'Название сервиса',
                text: 'Короткое описание сервиса в пару строк. '
            }

        ],
        [MenuType.CloudStorage]: [
            {
                id: 1,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
            {
                id: 2,
                icon: harvestIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 3,
                icon: clockifyIcon,
                title: 'Time Tracking Integration',
                text: 'Track time on tasks right from Apilon with just one click. '
            },
            {
                id: 4,
                icon: duefocusIcon,
                title: 'Duefocus time tracking',
                text: 'Fully friendly with your project management tool'
            },
            {
                id: 5,
                icon: everhourIcon,
                title: 'Everhour Time Tracking',
                text: 'Everhour makes budgeting and invoicing simple!'
            },
            {
                id: 6,
                icon: timelyIcon,
                title: 'Название сервиса',
                text: 'Короткое описание сервиса в пару строк. '
            },
            {
                id: 7,
                icon: chromeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 8,
                icon: pomodoneIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 9,
                icon: timeneyeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 10,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
        ],
        [MenuType.Developers]: [
            {
                id: 1,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
            {
                id: 2,
                icon: harvestIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 3,
                icon: clockifyIcon,
                title: 'Time Tracking Integration',
                text: 'Track time on tasks right from Apilon with just one click. '
            },
            {
                id: 4,
                icon: duefocusIcon,
                title: 'Duefocus time tracking',
                text: 'Fully friendly with your project management tool'
            },
            {
                id: 5,
                icon: everhourIcon,
                title: 'Everhour Time Tracking',
                text: 'Everhour makes budgeting and invoicing simple!'
            },
            {
                id: 6,
                icon: timelyIcon,
                title: 'Название сервиса',
                text: 'Короткое описание сервиса в пару строк. '
            },
            {
                id: 7,
                icon: chromeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 8,
                icon: pomodoneIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            }
        ],
        [MenuType.Calendar]: [
            {
                id: 1,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
            {
                id: 2,
                icon: harvestIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 3,
                icon: clockifyIcon,
                title: 'Time Tracking Integration',
                text: 'Track time on tasks right from Apilon with just one click. '
            },
            {
                id: 4,
                icon: duefocusIcon,
                title: 'Duefocus time tracking',
                text: 'Fully friendly with your project management tool'
            },
            {
                id: 5,
                icon: everhourIcon,
                title: 'Everhour Time Tracking',
                text: 'Everhour makes budgeting and invoicing simple!'
            },
            {
                id: 6,
                icon: timelyIcon,
                title: 'Название сервиса',
                text: 'Короткое описание сервиса в пару строк. '
            },
            {
                id: 7,
                icon: chromeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 8,
                icon: pomodoneIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 9,
                icon: timeneyeIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            }
        ],
        [MenuType.More]: [
            {
                id: 1,
                icon: toggleIcon,
                title: 'Toggl Time Tracking',
                text: 'Associate time tracked with tasks automatically.',
            },
            {
                id: 2,
                icon: harvestIcon,
                title: 'Harvest Time Tracking',
                text: 'Modern time tracking software'
            },
            {
                id: 3,
                icon: clockifyIcon,
                title: 'Time Tracking Integration',
                text: 'Track time on tasks right from Apilon with just one click. '
            },
            {
                id: 4,
                icon: duefocusIcon,
                title: 'Duefocus time tracking',
                text: 'Fully friendly with your project management tool'
            },
            {
                id: 5,
                icon: everhourIcon,
                title: 'Everhour Time Tracking',
                text: 'Everhour makes budgeting and invoicing simple!'
            },
            {
                id: 6,
                icon: timelyIcon,
                title: 'Название сервиса',
                text: 'Короткое описание сервиса в пару строк. '
            }
        ]
    }
}